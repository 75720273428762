import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Papa from 'papaparse';
import '../App.css';

function Main() {
    return (
        <div className="main-section">
            <div className="section-header">
                <h3>The average IQ in Turkey is 89</h3>
            </div>
            <div className="section-paragraph">
                <p>Take this IQ test and check what is your IQ</p>
            </div>
            <button type="button" className="btn btn-warning custom-button">Start IQ Test</button>
            <div className="additional-info">
                <ul>
                    <li>- 30 Questions</li>
                    <li>- Created by the WW IQ Test Research Team</li>
                    <li>- Get your IQ Certificate</li>
                    <li>- Get Detailed Performance Report</li>
                </ul>
            </div>
        </div>
    );
}

function NavSection() {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const fetchIQData = () => {
            Papa.parse(`${process.env.PUBLIC_URL}/assets/avgIQpercountry.csv`, {
                download: true,
                header: true,
                complete: (results) => {
                    const sortedData = results.data.sort((a, b) => parseFloat(b['Average IQ']) - parseFloat(a['Average IQ'])); 
                    const top30Countries = sortedData.slice(0, 30); 
                    setCountries(top30Countries);
                },
                error: (error) => {
                    console.error('Error fetching CSV data:', error);
                },
            });
        };

        fetchIQData();
    }, []);

    const columnSize = Math.ceil(countries.length / 5);
    const columns = [
        countries.slice(0, columnSize),
        countries.slice(columnSize, 2 * columnSize),
        countries.slice(2 * columnSize, 3 * columnSize),
        countries.slice(3 * columnSize, 4 * columnSize),
        countries.slice(4 * columnSize, countries.length)
    ];

    return (
        <nav className="nav-section" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
            <h4 style={{ textAlign: 'center', marginBottom: '40px', fontSize: '24px' }}>
                Top 30 Countries by Average IQ
            </h4>
            <div className="row justify-content-center">
                {columns.map((column, columnIndex) => (
                    <div key={columnIndex} className="col-md-2">
                        <ul className="list-group">
                            {column.map((country, index) => (
                                <li 
                                    key={index} 
                                    className="list-group-item" 
                                    style={{ fontSize: '12px', marginBottom: '10px', padding: '10px', textAlign: 'center' }}
                                >
                                    <strong>{country.Country}</strong>: {country['Average IQ']}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </nav>
    );
}

function NavImprove() {
    return (
        <section className="nav-improve">
            <div className="info-item">
                    <span className="info-number">World Wide IQ Test</span>
                    <span className="info-text">The World Wide IQ Test is part of an ambitious effort to deliver fun</span>
                </div>
            <div className="background-image"></div>
            <div className="info-section">
                <div className="info-item">
                    <span className="info-number">01.</span>
                    <span className="info-text">Take the IQ Test<br/>Designed by expert psychologists and test developers</span>
                </div>
                <div className="info-item">
                    <span className="info-number">02.</span>
                    <span className="info-text">Find out your scores<br/>Get your customized score report and learn how you compare to thousands of adults from all over the world</span>
                </div>
                <div className="info-item">
                    <span className="info-number">03.</span>
                    <span className="info-text">Tell your friends all about it<br/>Challenge your friends to a battle of the brains!</span>
                </div>
            </div>
        </section>
    );
}

function App() {
    return (
        <>
            <Main />
            <NavSection />
            <NavImprove />
        </>
    );
}

export default App;
