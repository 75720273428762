import React, { useEffect, useState } from 'react';
import '../test.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Test() {
    const [timeLeft, setTimeLeft] = useState(20 * 60); // 20 dakika (saniye cinsinden)

    useEffect(() => {
        // Her saniye geri sayımı güncelle
        const intervalId = setInterval(() => {
            setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        // Bileşen kaldırıldığında interval'ı temizle
        return () => clearInterval(intervalId);
    }, []);

    // Dakika ve saniyeye çeviren format fonksiyonu
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div>
            <div className="test-main">
                {/* Üst kısım: 20vh yüksekliğinde sarı bölüm */}
                <div className="top-section">
                <img src={`${process.env.PUBLIC_URL}/assets/kronometre.png`} alt="Kronometre" className="timer-icon" />
                    <h5>SÜRE: {formatTime(timeLeft)}</h5> {/* Kronometreyi göster */}
                </div>

                {/* Ana içerik */}
                <div className="test-main-content">
                    <div className="left-section">
                        <div className="section">
                            <h5>SORU</h5>
                        </div>
                    </div>
                    <div className="right-section">
                        <div className="section">
                            <h5>SEÇENEKLER</h5>
                        </div>
                    </div>
                </div>

                {/* Alt kısım: 20vh yüksekliğinde sarı bölüm */}
                <div className="bottom-section">
                    <h5>SORUYU DEĞİŞTİR</h5>
                </div>
            </div>
        </div>
    );
}

export default Test;
