import React from 'react';
import '../App.css'; // Footer için stil dosyası

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <h5>İletişim</h5>
                <p>Adres: Örnek Cad. No:1, İstanbul</p>
                <p>Email: example@example.com</p>
                <p>Telefon: +90 123 456 7890</p>
            </div>
        </footer>
    );
}

export default Footer;
