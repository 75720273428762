import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Routes yerine Switch kullanılabilir, ama Routes ile v6 uyumlu
import Navbar from './components/Navbar';
import Main from './components/main';
import Footer from './components/footer';
import Test from './components/test';

function App() {
    return (
        
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/test" element={<Test />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
