import React from 'react';
import { useNavigate } from 'react-router-dom'; // useNavigate import et

function Navbar() {
  const navigate = useNavigate(); // useNavigate hook'unu kullan

  const handleTestStart = () => {
    navigate('./test'); // '/test' yoluna yönlendir
  };

  return (
    <nav className="navbar navbar-expand-lg custom-navbar">
      <a className="navbar-brand" href="#">IQ TEST</a>
      <button 
        className="navbar-toggler" 
        type="button" 
        data-toggle="collapse" 
        data-target="#navbarNav" 
        aria-controls="navbarNav" 
        aria-expanded="false" 
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <a className="nav-link" href="./App">Home <span className="sr-only">(current)</span></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Hakkımızda</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">IQTest (Demo)</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Blog Yazıları</a>
          </li>
          <button 
            type="button" 
            className="btn btn-warning custom-button"
            onClick={handleTestStart} // Butona tıklama işlevi ekle
          >
            IQ testini başlat
          </button>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
